export default defineNuxtRouteMiddleware(async (to, from) => {
  const host = window.location.host;
  const subdomain = host.split(".")[0];
  const auth = useAuth();
  const config = useRuntimeConfig();

  if (!auth.user?.accounts_count) {
    return navigateTo(`${config.public.primaryURL}/onboarding`, {
      external: true,
    });
  }

  if (subdomain !== "app" && to.path === "/switch") {
    return navigateTo("/hub/sessions");
  } else {
    return;
  }
});
